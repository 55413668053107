import { trigger, state, style, animate, transition } from '@angular/animations';

export const slideIn = trigger('slideIn', [
    state('hidden', style({
        opacity: 0,
        transform: 'translateX(-100%)'
    })),
    state('visible', style({
        opacity: 1,
        transform: 'translateX(0)'
    })),
    transition('hidden => visible', [
        animate('500ms ease-in-out')
    ])
]);


export const slideUpToBottom = trigger('slideUpToBottom', [
    state(
        'descendo', // Define um estado chamado 'descendo'
        style({
            transform: 'translateY(0)', // A animação começa no topo da tela (posição final: 0px no eixo Y).
            opacity: 1, // Opacidade 1 significa visível.
        })
    ),

    state(
        'recuando', // Define um estado chamado 'recuando'
        style({
            transform: 'translateY(-230px)', // O elemento se move para cima, para fora da tela no eixo Y (230px acima).
            opacity: 0, // Opacidade 0 significa invisível.
        })
    ),

    state(
        'leftToRight', // Define um estado chamado 'leftToRight'
        style({
            transform: 'translateX(0)', // A posição inicial no eixo X é 0 (centro ou no seu lugar original).
            opacity: 1, // Opacidade 1 significa visível.
        })
    ),

    state(
        'enteringFromLeft', // Define um estado chamado 'enteringFromLeft'
        style({
            transform: 'translateX(-100%)', // A animação começa com o elemento à esquerda fora da tela (100% fora da tela).
            opacity: 0, // Opacidade 0 significa invisível, a animação começa de forma invisível.
        })
    ),

    // Transição entre os estados
    transition('descendo => recuando', [animate('0.2s ease-in-out')]),
    // Quando o estado vai de 'descendo' para 'recuando', o elemento se move para cima (no eixo Y)
    // A animação leva 0.2 segundos e usa a função de tempo 'ease-in-out' para dar suavidade no início e no final da animação.

    transition('recuando => descendo', [
        style({ opacity: 0 }), // O elemento começa invisível (opacity: 0)
        animate('0.5s ease-in-out') // Em seguida, a animação é aplicada para mover o elemento para sua posição original
        // (no eixo Y), com duração de 0.5 segundos e suavidade usando 'ease-in-out'.
    ]),

    // Transição de 'enteringFromLeft' para 'leftToRight'
    transition('enteringFromLeft => leftToRight', [animate('0.5s ease-out')]),
    // Quando o estado vai de 'enteringFromLeft' para 'leftToRight', o elemento se move de volta para a posição
    // original no eixo X. A animação dura 0.5 segundos, com 'ease-out' (a animação fica mais rápida no início e desacelera no final).

    // Transições gerais (de qualquer estado para 'recuando')
    transition('* => recuando', [animate('0.2s ease-in-out')]),
    // Essa transição define um comportamento para quando o estado atual (não importa qual) vai para 'recuando'.
    // A animação é a mesma de 'descendo => recuando' e move o elemento para cima (no eixo Y) com opacidade reduzida (0).

    // Transições gerais (de qualquer estado para 'enteringFromLeft')
    transition('* => enteringFromLeft', [animate('0.3s ease-in-out')]),
    // Essa transição define um comportamento para quando o estado atual vai para 'enteringFromLeft'.
    // O elemento começa invisível e se move da esquerda para a posição original no eixo X, levando 0.3 segundos para a animação.
    // A função de tempo usada é 'ease-in-out', o que significa que a animação começa devagar, acelera no meio e desacelera no final.

]);